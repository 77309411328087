/* Form Container */
.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  /* Form */
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-size: 14px;
    font-weight: bold;
    color: #555;
    margin-bottom: 5px;
    display: block;
  }
  
  input,
  textarea,
  select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  textarea {
    resize: none;
    height: 80px;
  }
  
  input:focus,
  textarea:focus,
  select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
  }
  
  /* Submit Button */
  .submit-button {
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .submit-button:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  /* Message */
  .message {
    text-align: center;
    font-size: 14px;
    color: #007bff;
    margin-bottom: 15px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .form-container {
      padding: 15px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    input,
    textarea,
    select {
      font-size: 14px;
    }
  }
  