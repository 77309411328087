.house-rent-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.house-rent-heading {
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.house-rent-heading p {
  font-size: 1.2em;
}

h3 {
  text-align: center;
  margin-bottom: 20px;
}
/* 
.house-rent-heading p {
  font-size: 1.2rem;
  color: #666;
} */

.search-filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0;
}

.search-filters-2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0;
}

.search-filters-2 input {
  flex: 1;
  margin: 5px;
  padding: 10px;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.search-filters input {
  flex: 1;
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-filters input:focus {
  border-color: #007BFF;
  outline: none;
}

.create-new-rent-container {
  text-align: center;
  margin-bottom: 20px;
}

.create-new-rent-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-new-rent-button:hover {
  background-color: #0056b3;
}

.rental-houses-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.rent-card {
  display: block;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-decoration: none;
  color: inherit;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.rent-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.rent-image {
  width: 100%;
  height: 200px; /* Set a fixed height */
  object-fit: cover; /* Cover the area without distortion */
}

.rent-card h4 {
  margin: 10px;
  font-size: 1.4rem;
  color: #333;
}

.rent-card p {
  margin: 5px 10px;
  font-size: 1rem;
  color: #555;
}

.no-rent-heading {
  text-align: center;
  color: #666;
  margin-top: 30px;
}

/* Gold stars for ratings */
.rating-stars {
  display: inline-block;
  color: gold; /* Set the stars to gold color */
  font-size: 1.2rem; /* Adjust the size as needed */
}

.rating-stars span {
  margin-right: 2px;
}

