/* Container Styling */
.rental-details-container {
    width: 96%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading */
  .rental-details-container h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Image Carousel */
  .image-carousel {
    width: 100%;
    max-width: 600px;
    margin: 0 auto 20px;
  }
  
  .image-carousel img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  /* Rental Details Text */
  .rental-details-text {
    font-size: 1rem;
    color: #333;
  }
  
  .rental-details-text p {
    margin: 10px 0;
    line-height: 1.5;
  }
  
  .rental-details-text strong {
    font-weight: bold;
  }
  
  /* Star Rating */
  .star-icon {
    color: #FFD700; /* Gold color for filled stars */
    font-size: 1.2rem;
    margin-right: 2px;
  }
  
  .star-icon.empty {
    color: #ddd; /* Grey color for empty stars */
  }
  
  /* Carousel Dots */
  .carousel .control-dots {
    bottom: 10px; /* Adjust position of dots */
  }
  
  .carousel .control-dots .dot {
    background: #333; /* Dark color for visibility */
    width: 18px; /* Larger dots */
    height: 18px; /* Larger dots */
    border-radius: 50%; /* Round dots */
    margin: 0 5px; /* Space between dots */
    transition: opacity 0.3s ease; /* Smooth effect */
  }
  
  .carousel .control-dots .dot.selected {
    background: #FFD700; /* Gold for selected */
    transform: scale(1.2); /* Highlight selected */
  }
  
  /* Inquiry Button */
  .inquiry-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #30a14a;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .inquiry-button:hover {
    background-color: #e09e41;
  }
  
  /* Image Sizing */
  .rental-details-container .image-carousel .img {
    width: 100%;
    max-width: 600px;
    max-height: 500px;
    object-fit: cover;
  }
  
  /* Thumbnails */
  .rental-details-container .image-carousel .carousel .thumbs img {
    height: 80px; 
    width: auto; 
    object-fit: cover;
  }

/* Responsive Adjustments */
@media (max-width: 768px) {
  .rental-details-container .image-carousel .img {
    max-height: 400px; /* Reduce height for tablets */
  }

  .rental-details-container .image-carousel .carousel .thumbs img {
    height: 70px; /* Slightly smaller thumbnails on tablets */
  }
}

@media (max-width: 576px) {
  .rental-details-container .image-carousel .img {
    max-height: 300px; /* Reduce height for smaller mobile devices */
  }

  .rental-details-container .image-carousel .carousel .thumbs img {
    height: 60px; /* Smaller thumbnails for mobile */
  }
}

  
  /* Social Share Buttons */
  .social-share {
    margin-top: 20px;
    text-align: center;
  }
  
  .share-button {
    display: inline-block;
    margin: 5px 10px;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
  
  .share-button.facebook {
    background-color: #3b5998;
  }
  
  .share-button.twitter {
    background-color: #1da1f2;
  }
  
  .share-button.whatsapp {
    background-color: #25d366;
  }
  
  .share-button.linkedin {
    background-color: #0077b5;
  }
  