.request-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .request-details-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .request-details-card p {
    margin: 10px 0;
    line-height: 1.5;
  }
  
  .request-details-card strong {
    font-weight: bold;
  }
  
  .request-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  .request-image {
    max-width: 100px;
    max-height: 100px;
    border-radius: 4px;
    object-fit: cover;
    border: 1px solid #ddd;
  }
  