/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

/* Navbar Container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  position: relative;
  z-index: 1000;
}

/* Logo and Brand */
.navbar-logo {
  display: flex;
  align-items: center;
}

.logo-link {
  text-decoration: none; /* Remove underline from the logo link */
  color: #fff; /* Ensure text color is white */
  display: flex; /* Align items horizontally */
  align-items: center; /* Center items vertically */
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.brand-name {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Navbar Links (Desktop) */
.navbar-links {
  display: flex;
  list-style: none;
  transition: all 0.3s ease-in-out;
}

.navbar-links li {
  margin: 0 15px;
}

.navbar-links a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #f0f0f0;
}

/* Hamburger Menu (Hidden on Desktop) */
.hamburger {
  display: none; /* Hide by default */
  flex-direction: column; /* Stack the lines vertically */
  cursor: pointer; /* Pointer cursor for better UX */
}

/* Show the hamburger in mobile view */
@media (max-width: 768px) {
  .hamburger {
    display: flex; /* Show the hamburger in mobile */
  }

  .navbar-links {
    display: none; /* Hide the links by default */
    position: absolute; /* Position links absolutely */
    top: 60px; /* Position below the navbar */
    right: 0; /* Align to the right */
    background-color: #333; /* Match background color */
    width: 100%; /* Full width */
    flex-direction: column; /* Stack links vertically */
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease; /* Smooth transition */
  }

  .navbar-links.active {
    display: flex; /* Show the links when active */
  }

  .navbar-links li {
    margin: 20px 0; /* Space between links in mobile */
  }
}

/* Hamburger Lines */
.line {
  height: 4px; /* Height of the lines */
  width: 30px; /* Width of the lines */
  background-color: white; /* Color of the lines */
  margin: 4px 0; /* Space between lines */
  border-radius: 2px; /* Rounded edges */
  transition: all 0.3s ease; /* Smooth transition for all changes */
  position: relative; /* Position relative for better control */
}

/* Transform the lines to form an "X" */
.cross {
  transition: transform 0.3s ease;
}

.cross.line1 {
  transform: rotate(45deg) translate(4px, 4px); /* Rotate line 1 */
}

.cross.line2 {
  transform: rotate(-45deg) translate(4px, -4px); /* Rotate line 2 */
}
