/* General Styles */
.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  background: #e9ecef;
  min-height: 100vh;
}

/* Wrapper with Border Radius and Background */
.home-wrapper {
  background: #f8f9fa;
  border-radius: 15px;
  padding: 30px 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 20px;
  justify-content: center;
  align-items: center;
}

/* Header */
.home-header {
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.home-header h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.home-header p {
  color: #555;
  font-size: 1.2rem;
  align-content: center;
}


.home-header h2 {
  color: #555;
  font-size: 1.2rem;
  align-content: center;
}

/* Search Form */
.search-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  background: #fff;
}

.input-icon {
  margin-right: 10px;
  color: #666;
}

.search-form input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  border-radius: 20px;
  font-size: 1rem;
  text-decoration: none;
  color: white;
  transition: background 0.3s;
  width: 180px;
  text-align: center;
}

.search-btn {
  margin-top: 10px;
  background: #007bff;
  border: none;
  cursor: pointer;
}

.search-btn:hover {
  background: #0056b3;
}

/* Error Message */
.error-message {
  color: red;
  margin-top: 10px;
}

/* Search Results */
.search-results {
  width: 100%;
  max-width: 500px;
  text-align: left;
  margin-top: 20px;
}

.search-results h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.search-results ul {
  list-style-type: none;
  padding: 0;
}

.search-results li {
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 5px;
}

/* App Download Links */
.app-links {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.ios-btn {
  background: black;
}

.android-btn {
  background: #34a853;
}

.ios-btn:hover {
  background: #333;
}

.android-btn:hover {
  background: #2c8c45;
}

/* Property Page Link */
.property-link {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.property-btn {
  background: #ff9800;
  padding: 10px 20px;
  border-radius: 8px;
}

.property-btn:hover {
  background: #e68900;
}

/* Floating Scroll Button */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s;
}

.floating-button:hover {
  background: #0056b3;
}

.search-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .home-header h1 {
    font-size: 1.8rem;
  }
  .home-header p {
    font-size: 1rem;
  }
  .home-header h2 {
    font-size: 1rem;
  }
  .search-form {
    max-width: 100%;
  }
  .app-links {
    flex-direction: column;
    align-items: center;
  }
  .btn {
    width: 90%;
    max-width: 250px;
  }
}

@media (max-width: 480px) {
  .home-header h1 {
    font-size: 1.5rem;
  }
  .home-header p {
    font-size: 0.9rem;
  }
  .home-header h2 {
    font-size: 0.9rem;
  }
  .search-btn {
    font-size: 0.9rem;
    padding: 8px;
  }
  .floating-button {
    width: 40px;
    height: 40px;
  }
}
