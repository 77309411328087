.private-bnb-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }

  .private-bnb-container-heading {
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .private-bnb-container-heading p {
    font-size: 1.2em;
  }
  .no-bnb-heading {
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .private-stays-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .stay-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from parent */
    transition: transform 0.3s;
    width: 300px; /* Set a fixed width */
  }
  
  .stay-card:hover {
    transform: scale(1.01); /* Scale effect on hover */
  }
  
  .stay-image {
    width: 100%;
    height: 200px; /* Set a fixed height */
    object-fit: cover; /* Cover the area without distortion */
  }
  
  h3 {
    margin: 10px;
    font-size: 1.6em;
  }

  h4 {
    margin: 10px;
    font-size: 24px;
  }
  
  p {
    margin: 5px 10px;
  }
  
  .create-new-home-container {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .create-new-home-button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .create-new-home-button:hover {
    background-color: #218838;
  }

  .stay-card span {
    color: #FFD700; /* Gold color for stars */
    font-size: 18px;
  }
  
.search-filters-2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0;
}

.search-filters-2 input {
  flex: 1;
  margin: 5px;
  padding: 10px;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}
  