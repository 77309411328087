.private-home-form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.private-home-form h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.toggle-container {
  display: "flex";
  align-items: "center";
  justify-content: "space-between";
  margin: "10px 0";
}
.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px; /* Space between checkbox and label */
}

.checkbox-container label {
  font-size: 16px;
  color: #333; /* Adjust color as needed */
  cursor: pointer;
}

.checkbox-container input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.private-home-form div {
  margin-bottom: 15px;
}

.private-home-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.private-home-form input[type="text"],
.private-home-form input[type="number"],
.private-home-form input[type="tel"],
.private-home-form input[type="date"],
.private-home-form select,
.private-home-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.private-home-form textarea {
  resize: vertical;
}

.private-home-form .star-rating {
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.private-home-form .star-rating span {
  font-size: 1.5rem;
  color: #f39c12; /* Color for the filled stars */
}

.private-home-form button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.private-home-form button:hover {
  background-color: #45a049;
}

.image-carousel {
  margin-top: 20px;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
}

.image-carousel img {
  width: 100%; /* Set to 100% to fill the carousel item */
  height: auto; /* Maintain aspect ratio */
  max-width: 250px; /* Set a max width for thumbnail size */
  max-height: 250px; /* Set a max height for thumbnail size */
  object-fit: cover; /* Ensure images cover the space without distortion */
  border-radius: 8px; /* Optional: adds rounded corners to thumbnails */
}

/* Container for the search filters */
.search-filters {
  display: flex;
  justify-content: center;
  gap: 15px; /* Space between the inputs */
  margin: 20px 0; /* Spacing above and below the filters */
}

/* Style each input in the search filters */
.search-filters input {
  padding: 10px;
  width: 200px;
  font-size: 16px;
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  outline: none;
  transition: border-color 0.3s ease;
}

/* Change border color on focus */
.search-filters input:focus {
  border-color: #3498db; /* Blue color on focus */
}

/* Style for mobile responsiveness */
@media (max-width: 768px) {
  .search-filters {
    flex-direction: column; /* Stack filters vertically on smaller screens */
    align-items: center;
  }

  .search-filters input {
    width: 100%; /* Make inputs full-width on small screens */
    max-width: 300px; /* Prevents inputs from getting too wide */
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 10px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.submit-container {
  text-align: center;
  margin-top: 20px;
}


