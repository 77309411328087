.requests-list-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab {
    padding: 10px 20px;
    margin: 0 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .tab.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }
  
  .filters {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .filters input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .requests-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .request-item {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .request-item:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .request-item h3 {
    margin: 0 0 10px;
    font-size: 18px;
  }
  
  .request-item p {
    margin: 5px 0;
  }
  
  
.create-new-request-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .create-new-request-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .create-new-request-button:hover {
    background-color: #0056b3;
  }